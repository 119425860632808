import React from "react";
import "./Contents.scss";

// carousel
import Carousel from "../Components/Carousel";

// lp data
import lpData from "../Data/LPData.js";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faImage,
  faVideo,
  faLink,
  faCode
} from "@fortawesome/free-solid-svg-icons";

const Contents = () => {
  const sectionData = lpData.Contents;

  // random rotation
  const randomRotation = (min = 0, max = 1) => {
    const random = Math.random() * (max - min) + min;
    const positive = Math.random() > 0.5 ? true : false; // generates true or false
    const value = random * (positive ? 1 : -1);
    return value;
  };

  return (
    <SectionWrapper
      bgColor={sectionData.background}
      fontColor={sectionData.fontColor}
      tight={true}
      wrapperClass="contents-wrapper"
      containerClass="contents-container"
      sectionTitle={sectionData.sectionTitle}
      sectionDescription={sectionData.sectionDescription}
    >
      <div className="block-types-section">
        <div className="icons">
          <FontAwesomeIcon icon={faFont} className="icon" />
          <FontAwesomeIcon icon={faImage} className="icon" />
          <FontAwesomeIcon icon={faVideo} className="icon" />
          <FontAwesomeIcon icon={faLink} className="icon" />
          <FontAwesomeIcon icon={faCode} className="icon" />
        </div>
        <p className="text">
          {sectionData.sectionText}
        </p>
      </div>
      <Carousel className="contents">
        {sectionData.contents.map((content, index) => {
          return (
            <div
              className="content"
              style={{
                transform: `rotate(${randomRotation(1, 3)}deg) translate(${
                  randomRotation() * 10
                }px, ${randomRotation() * 25}px)`,
              }}
            >
              {content.title && <span className="title"># {content.title}</span>}
              <img src={content.image} className="screenshot" alt="" />
            </div>
          );
        })}
      </Carousel>
      <div className="sample-section">
        <a href="https://pulp-land.notion.site/sample?pvs=4" className="underline" target="_blank">サンプルを見てみる</a>
      </div>
    </SectionWrapper>
  );
};

export default Contents;

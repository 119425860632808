import { Fragment } from "react";

const lpData = {
  v1: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/placeholders/mockup.png",
      mainMessage: (
        <Fragment>
          <p className="small">あなたの全てを、一つのプロフィールに。</p>
          <p>
            コンテンツも作れるPulpで、あなたのクリエイタースペースを作ろう。
          </p>
        </Fragment>
      ),
    },
    Introduction: {
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }}>
            クリエイターのためのコンテンツスペース
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "没入感のあるコンテンツを、スマホで作成",
        "一つのPulpプロフィールリンクで、あなたのスペースにすぐにアクセス",
        "あなたのタイミングで、あなたらしいマネタイズを実現",
      ],
    },
    WhatYouCanCreate: {
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      // sectionTitle: "スマホ一つで始める、あなたのPulp。",
      sectionTitle: "何が作れるの？",
      sectionDescription:
        "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "Your everything, unified.",
          title: "あなたの全てを、一つに。",
          body: "あなたのコンテンツや各種リンクを、プロフィール画面にまとめましょう。Pulpプロフィールのリンク一つで、SNSだけでは伝えきれないことも全て伝えることができます。",
          image: "/images/placeholders/mockup.png",
        },
        {
          preTitle: "Content x People",
          title: "コンテンツもメンバーも、Pulpで完結。",
          body: "作ったコンテンツは、「バスケット機能」を使ってトピックごとにまとめましょう。フォロワーさんや限定メンバー・購入者など、あなたに興味を持ってくれた方々は、「ピープル」セクションにまとめて表示。グループを作成すれば、特定のファンにのみコンテンツを届けたり、メルマガを送信したりできるようになります。",
          image: "/images/placeholders/mockup.png",
        },
        {
          preTitle: "Monetize your way",
          title: "あなたらしいコンテンツで、あなたらしいマネタイズを実現。",
          body: "Pulpなら、無料のコンテンツも、有料コンテンツも、自由自在に提供可能。買い切りやサブスク、バンドルから投げ銭まで、多様なマネタイズ手法で、あなたらしく、違和感のないマネタイズを実現。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Features: {
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
  },

  // 俊作とキャッチなどを合意。
  v2: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/placeholders/mockup.png",
      mainMessage: (
        <Fragment>
          <h1>スマホでつくる、あなただけのクリエイタースペース。</h1>
          <p className="small">
            ファンの獲得からマネタイズまで、まるっと。あなたらしい教え方ができる、
            <span className="underline">
              クリエイターのためのホームツール。
            </span>
          </p>
        </Fragment>
      ),
    },
    Introduction: {
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }}>
            クリエイターのためのコンテンツスペース
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "没入感のあるコンテンツを、スマホで作成",
        "一つのPulpプロフィールリンクで、あなたのスペースにすぐにアクセス",
        "あなたのタイミングで、あなたらしいマネタイズを実現",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionTitle: "何が作れるの？",
      sectionDescription:
        "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "Your everything, unified.",
          title: "あなたの全てを、一つに。",
          body: "あなたのコンテンツや各種リンクを、プロフィール画面にまとめましょう。Pulpなら、コンテンツ・活動・ファンを、あなたらしく一つにできます。Pulpプロフィールのリンク一つで、SNSだけでは伝えきれないことも全て伝えることができます。",
          image: "/images/placeholders/mockup.png",
        },
        {
          preTitle: "Immersive experience",
          title: "没入感で、惹きつける。",
          body: "作ったコンテンツは、「バスケット機能」を使ってトピックごとにまとめましょう。フォロワーさんや限定メンバー・購入者など、あなたに興味を持ってくれた方々は、「ピープル」セクションにまとめて表示。グループを作成すれば、特定のファンにのみコンテンツを届けたり、メルマガを送信したりできるようになります。",
          image: "/images/placeholders/mockup.png",
        },
        {
          preTitle: "Monetize your way",
          title: "あなたらしいマネタイズを、あなたのタイミングで。",
          body: "Pulpなら、無料のコンテンツも、有料コンテンツも、自由自在に提供可能。買い切りやサブスク、バンドルから投げ銭まで、多様なマネタイズ手法で、あなたらしく、違和感のないマネタイズを実現。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
  },

  // v2にsmallテキストなどを修正・追加したもの。
  v3: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    apiEndpoint: "https://lekcha-blocks-api.herokuapp.com",
    // apiEndpoint: "http://localhost:4000",
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/screenshots/new/home.png",
      mainMessage: (
        <Fragment>
          <h1>
            スマホでつくる、
            <br />
            あなただけのクリエイタースペース。
          </h1>
          <p className="small">
            ファンの獲得からマネタイズまで、まるっと。あなたらしい教え方ができる、
            <span className="underline">
              クリエイターのためのホームツール。
            </span>
          </p>
        </Fragment>
      ),
    },
    Introduction: {
      hidden: true,
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }} className="underline">
            クリエイターのためのホームツール
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "コンテンツも、プロフィールも、ファンも、一つに。",
        "Pulpリンク一つで、あなたのだけのスペースにすぐにアクセス。",
        "あなたのタイミングで、あなたらしいマネタイズを実現。",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionTitle: "何が作れるの？",
      sectionDescription: "Pulpを使うと、こんなことが実現します。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "Your everything, unified.",
          title: "あなたの全てを、一つに。",
          body: (
            <Fragment>
              コンテンツもプロフィールもファンも、すべてが1つのクリエイタースペースに。
              <span className="underline">
                あなた専用のPulpリンク一つで、SNSだけでは伝えきれないことも全て伝えることができます。
              </span>
            </Fragment>
          ),
          image: "/images/screenshots/new/profile.png",
        },
        {
          preTitle: "Immersive experience",
          title: "没入感で、惹きつける。",
          body: (
            <Fragment>
              Pulpなら、スマホで気軽にコンテンツ作成。テキストも画像も動画もリンクも自在に組み合わせることができます。1段落,
              1画像, 1動画からでも、
              <span className="underline">
                没入感のあるコンテンツを、誰でも簡単につくることができます。
              </span>
            </Fragment>
          ),
          // image: "/images/screenshots/new/content.png",
          image: "/images/screenshots/new/content.gif",
        },
        {
          preTitle: "Monetize your way",
          title: "あなたらしいマネタイズを、あなたのタイミングで。",
          body: (
            <Fragment>
              Pulpなら、無料のコンテンツも、有料コンテンツも、自由自在に提供可能。
              <span className="underline">
                買い切りやサブスク、バンドルから投げ銭まで、多様なマネタイズ手法で、あなたらしく、違和感のないマネタイズを実現。
              </span>
            </Fragment>
          ),
          image: "/images/screenshots/new/monetize.png",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
    Creators: {
      sectionTitle: "Pulpのクリエイターたち",
      sectionDescription: "私たちと一緒に、あなたの知識を解放しませんか？",
      background: "white",
      fontColor: "#292e34",
      creators: [
        {
          occupation: "編み物作家・講師",
          name: "AKKO",
          comment: (
            <Fragment>
              自分のオンライン教室が簡単に開けるツールPulp。
              <span className="underline">
                SNSのショート動画のような感覚で始められる
              </span>
              から、気軽に動画レッスンを作っていけます！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "tsukurite_akko",
            url: "https://www.instagram.com/tsukurite_akko/",
          },
          image: "/images/creators/akko-san.jpeg",
        },
        {
          occupation: "インスタグラマー",
          name: "ゆき英語",
          comment: (
            <Fragment>
              Pulpを使えば、見やすく・学びやすいコンテンツが簡単に作れる。
              <span className="underline">
                作成から共有までこれひとつで完結する
              </span>
              から、コミュニティでの利用にも最適です！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "yuki_english2022",
            url: "https://www.instagram.com/yuki_english2022/",
          },
          image: "/images/creators/yuki-san.png",
        },
        {
          occupation: "フィギュアクリエイター",
          name: "渡部学",
          comment: (
            <Fragment>
              Pulpなら、サクサク講座が作れる。
              <span className="underline">
                文字が多めのコンテンツでも、ポップで見やすく仕上がります！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "YT",
            handle: "watabemanabu",
            url: "https://www.youtube.com/@watabemanabu",
          },
          image: "/images/creators/watabe-san.webp",
        },
        {
          occupation: "幸せ脳メソッド創始者・開発者",
          name: "青髪先生(牧野かよ)",
          comment: (
            <Fragment>
              新感覚ツールPulp。使うとわかる…かわいい！
              <span className="underline">
                これひとつで、自己紹介 • SNS • 講座のハブになる
              </span>
              ので、すごいです。
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "happybrain_makino",
            url: "https://www.instagram.com/happybrain_makino/",
          },
          image: "/images/creators/aogami-san.jpeg",
        },
        {
          occupation: "FP講師",
          name: "おーちゃん",
          comment: (
            <Fragment>
              専門知識なしでも、「指一本」で感覚的にコンテンツが作成できます。
              <span className="underline">
                やりたいことを自由自在に表現できる、まさにこんなツールが欲しかった！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "ochan1classfp",
            url: "https://twitter.com/ochan1classfp",
          },
          image: "/images/creators/ochan-san.png",
        },
        {
          // occupation: "",
          name: "Putti Monkey Wrench",
          comment: (
            <Fragment>
              <span className="underline">
                SNSには載せきれないノウハウを共有したり、作品の解説や、ポートフォリオとして使ったり。
              </span>
              様々な用途でコンテンツを作れそうなPulpのこれからが楽しみです。
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "PuttiMW",
            url: "https://twitter.com/PuttiMW",
          },
          image: "/images/creators/putti-san.jpg",
        },
      ],
    },
    Contents: {
      hidden: true,
      sectionTitle: "スマホから、自由自在に、表現しよう。",
      // sectionDescription:
      //   "今日も、Pulpでいろんなコンテンツが作られています。",
      // background: "#292e34",

      sectionText: (
        <Fragment>
          多様なブロックを組み合わせて、表現したいことをそのままコンテンツに。
          <br />
          <span className="underline">
            さまざまなクリエイターが、Pulpコンテンツの新しい使い方を生み出しています。
          </span>
        </Fragment>
      ),
      background: "#9ad0e6",
      fontColor: "white",
      contents: [
        {
          image: "/images/contents/1.png",
          title: "ミニ講座",
        },
        {
          image: "/images/contents/2.png",
          title: "講座・レッスンの案内ページ",
        },
        {
          image: "/images/contents/3.png",
          title: "プロフィールページ",
        },
        {
          image: "/images/contents/4.png",
          title: "Canvaの使い方",
        },
        {
          image: "/images/contents/5.png",
          title: "Creator's Book",
        },
        {
          image: "/images/contents/6.png",
          title: "アニメーションの作り方",
        },
        {
          image: "/images/contents/7.png",
          title: "便利な知識集",
        },
      ],
    },
    Signup: {
      sectionTitle: "あなたも、始めてみませんか？",
      sectionDescription: "Pulpは、事前登録を受け付けています。",
      background: "linear-gradient(45deg, #4ABDE1 0%, #A4E9FF 100%)",
      fontColor: "white",
      buttonText: "使ってみる！",
      imgUrl: "/images/join_pulp.png",
      formUrl: "https://forms.gle/81GKbDQ6LindWdP57",
    },
    Footer: {
      corporateSite: "https://lekcha.com/company",
      termsOfService: "https://lekcha.com/terms.html",
      privacyPolicy: "https://lekcha.com/privacy.html",
      instagram: "https://www.instagram.com/pulp_lekcha/",
    },
  },

  v4: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    apiEndpoint: "https://lekcha-blocks-api.herokuapp.com",
    // apiEndpoint: "http://localhost:4000",
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/screenshots/new/home.png",
      mainMessage: (
        <Fragment>
          <h1>
            ここから始まる、
            <br />
            あなたのファンづくり、あなたのマネタイズ。
          </h1>
          <p className="small">
            見込み客の獲得・ナーチャリングから、有料コンテンツや講座でのマネタイズまで、
            <span className="underline">“これひとつ” で完結。</span>
          </p>
        </Fragment>
      ),
    },
    Introduction: {
      hidden: true,
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }} className="underline">
            クリエイターのためのホームツール
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "コンテンツも、プロフィールも、ファンも、一つに。",
        "Pulpリンク一つで、あなたのだけのスペースにすぐにアクセス。",
        "あなたのタイミングで、あなたらしいマネタイズを実現。",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionTitle: "何が作れるの？",
      sectionDescription: "Pulpを使うと、こんなことが実現します。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "New kind of contents",
          title: "ファンを魅了する、没入感のあるコンテンツ。",
          body: (
            <Fragment>
              Pulpなら、スマホで気軽にコンテンツ作成。テキストも画像も動画もリンクも自在に組み合わせることができます。1段落,
              1画像, 1動画からでも、
              <span className="underline">
                没入感のあるコンテンツを、誰でも簡単につくることができます。
              </span>
            </Fragment>
          ),
          // image: "/images/screenshots/new/content.png",
          image: "/images/screenshots/new/content.gif",
        },
        {
          preTitle: "Unified, for the first time.",
          title: "わずらわしい作業は、全て一元化。",
          body: (
            <Fragment>
              複数のツールを利用したり、コンテンツを別々に保管して混乱する必要はありません。「コンテンツ管理」と「リスト管理」の両方が備わるPulpなら、
              <span className="underline">
                ファンの獲得からマネタイズまで一気通貫。
              </span>
            </Fragment>
          ),
          image: "/images/value/all-in-one.png",
        },
        {
          preTitle: "Your presence, closer to people.",
          title: "あなただけの空間を、ファンに直接。",
          body: (
            <Fragment>
              コンテンツもプロフィールもファンも、すべてが1つのクリエイタースペースに。
              <span className="underline">
                あなた専用のPulpリンク一つで、SNSだけでは伝えきれないことも全て伝えることができます。
              </span>
            </Fragment>
          ),
          image: "/images/screenshots/new/home.png",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
    Creators: {
      sectionTitle: "Pulpのクリエイターたち",
      sectionDescription: "私たちと一緒に、あなたの知識を解放しませんか？",
      background: "white",
      fontColor: "#292e34",
      creators: [
        {
          occupation: "編み物作家・講師",
          name: "AKKO",
          comment: (
            <Fragment>
              自分のオンライン教室が簡単に開けるツールPulp。
              <span className="underline">
                SNSのショート動画のような感覚で始められる
              </span>
              から、気軽に動画レッスンを作っていけます！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "tsukurite_akko",
            url: "https://www.instagram.com/tsukurite_akko/",
          },
          image: "/images/creators/akko-san.jpeg",
        },
        {
          occupation: "インスタグラマー",
          name: "ゆき英語",
          comment: (
            <Fragment>
              Pulpを使えば、見やすく・学びやすいコンテンツが簡単に作れる。
              <span className="underline">
                作成から共有までこれひとつで完結する
              </span>
              から、コミュニティでの利用にも最適です！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "yuki_english2022",
            url: "https://www.instagram.com/yuki_english2022/",
          },
          image: "/images/creators/yuki-san.png",
        },
        {
          occupation: "フィギュアクリエイター",
          name: "渡部学",
          comment: (
            <Fragment>
              Pulpなら、サクサク講座が作れる。
              <span className="underline">
                文字が多めのコンテンツでも、ポップで見やすく仕上がります！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "YT",
            handle: "watabemanabu",
            url: "https://www.youtube.com/@watabemanabu",
          },
          image: "/images/creators/watabe-san.webp",
        },
        {
          occupation: "幸せ脳メソッド創始者・開発者",
          name: "青髪先生(牧野かよ)",
          comment: (
            <Fragment>
              新感覚ツールPulp。使うとわかる…かわいい！
              <span className="underline">
                これひとつで、自己紹介 • SNS • 講座のハブになる
              </span>
              ので、すごいです。
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "happybrain_makino",
            url: "https://www.instagram.com/happybrain_makino/",
          },
          image: "/images/creators/aogami-san.jpeg",
        },
        {
          occupation: "FP講師",
          name: "おーちゃん",
          comment: (
            <Fragment>
              専門知識なしでも、「指一本」で感覚的にコンテンツが作成できます。
              <span className="underline">
                やりたいことを自由自在に表現できる、まさにこんなツールが欲しかった！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "ochan1classfp",
            url: "https://twitter.com/ochan1classfp",
          },
          image: "/images/creators/ochan-san.png",
        },
        {
          // occupation: "",
          name: "Putti Monkey Wrench",
          comment: (
            <Fragment>
              <span className="underline">
                SNSには載せきれないノウハウを共有したり、作品の解説や、ポートフォリオとして使ったり。
              </span>
              様々な用途でコンテンツを作れそうなPulpのこれからが楽しみです。
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "PuttiMW",
            url: "https://twitter.com/PuttiMW",
          },
          image: "/images/creators/putti-san.jpg",
        },
      ],
    },
    Contents: {
      hidden: true,
      sectionTitle: "スマホから、自由自在に、表現しよう。",
      // sectionDescription:
      //   "今日も、Pulpでいろんなコンテンツが作られています。",
      // background: "#292e34",

      sectionText: (
        <Fragment>
          多様なブロックを組み合わせて、表現したいことをそのままコンテンツに。
          <br />
          <span className="underline">
            さまざまなクリエイターが、Pulpコンテンツの新しい使い方を生み出しています。
          </span>
        </Fragment>
      ),
      background: "#9ad0e6",
      fontColor: "white",
      contents: [
        {
          image: "/images/contents/1.png",
          title: "ミニ講座",
        },
        {
          image: "/images/contents/2.png",
          title: "講座・レッスンの案内ページ",
        },
        {
          image: "/images/contents/3.png",
          title: "プロフィールページ",
        },
        {
          image: "/images/contents/4.png",
          title: "Canvaの使い方",
        },
        {
          image: "/images/contents/5.png",
          title: "Creator's Book",
        },
        {
          image: "/images/contents/6.png",
          title: "アニメーションの作り方",
        },
        {
          image: "/images/contents/7.png",
          title: "便利な知識集",
        },
      ],
    },
    Signup: {
      sectionTitle: "あなたも、始めてみませんか？",
      sectionDescription: "Pulpは、事前登録を受け付けています。",
      background: "linear-gradient(45deg, #4ABDE1 0%, #A4E9FF 100%)",
      fontColor: "white",
      buttonText: "使ってみる！",
      imgUrl: "/images/join_pulp.png",
      formUrl: "https://forms.gle/81GKbDQ6LindWdP57",
    },
    Footer: {
      corporateSite: "https://lekcha.com/company",
      termsOfService: "https://lekcha.com/terms.html",
      privacyPolicy: "https://lekcha.com/privacy.html",
      instagram: "https://www.instagram.com/pulp_lekcha/",
    },
  },

  // website builder value proposition
  v5: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    apiEndpoint: "https://lekcha-blocks-api.herokuapp.com",
    // apiEndpoint: "http://localhost:4000",
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/v5/hero.png",
      mainMessage: (
        <Fragment>
          <h1>
            魅力が伝わるホームページを、
            <br />
            スマホから。
          </h1>
          <p className="small">
            Pulp(パルプ)は、スマホで見やすいホームページを、
            <span className="underline">スマホで簡単に作成できる</span>
            サービスです。
          </p>
        </Fragment>
      ),
    },
    Introduction: {
      hidden: true,
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }} className="underline">
            クリエイターのためのホームツール
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "コンテンツも、プロフィールも、ファンも、一つに。",
        "Pulpリンク一つで、あなたのだけのスペースにすぐにアクセス。",
        "あなたのタイミングで、あなたらしいマネタイズを実現。",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "Pulpの特徴",
      // sectionTitle: "何が作れるの？",
      // sectionDescription: "Pulpを使うと、こんなことが実現します。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "Create anywhere, anytime.",
          title: "スキマ時間でも編集できる手軽さ。",
          body: (
            <Fragment>
              スマホに特化された編集画面で、直感的にページを作成・更新できます。
              <span className="underline">
                もう、更新の度にパソコンを立ち上げる必要はありません。
              </span>
            </Fragment>
          ),
          image: "/images/v5/values/1a.gif",
        },
        {
          preTitle: "Easy, but customizable.",
          title: "ハードルは低く、アレンジは無限大。",
          body: (
            <Fragment>
              1つの文章, 画像, 動画から、自由に組み合わせた体系的なページまで。
              <span className="underline">
                小さく作ることも、リッチな内容にすることも、あなたの自由です。
              </span>
            </Fragment>
          ),
          image: "/images/v5/values/2.png",
        },
        {
          preTitle: "Your presence, closer to people.",
          title: "大切な訪問者に、最高の閲覧体験を。",
          body: (
            <Fragment>
              デザインが苦手でも大丈夫。中身を追加して、カラーを選択するだけで、没入感のある
              <span className="underline">
                スマホに最適化されたページに仕上がります。
              </span>
            </Fragment>
          ),
          image: "/images/v5/values/3a.gif",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
    Creators: {
      sectionTitle: "Pulpのクリエイターたち",
      sectionDescription: "私たちと一緒に、あなたの知識を解放しませんか？",
      background: "white",
      fontColor: "#292e34",
      creators: [
        {
          occupation: "編み物作家・講師",
          name: "AKKO",
          comment: (
            <Fragment>
              自分のオンライン教室が簡単に開けるツールPulp。
              <span className="underline">
                SNSのショート動画のような感覚で始められる
              </span>
              から、気軽に動画レッスンを作っていけます！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "tsukurite_akko",
            url: "https://www.instagram.com/tsukurite_akko/",
          },
          image: "/images/creators/akko-san.jpeg",
        },
        {
          occupation: "インスタグラマー",
          name: "ゆき英語",
          comment: (
            <Fragment>
              Pulpを使えば、見やすく・学びやすいコンテンツが簡単に作れる。
              <span className="underline">
                作成から共有までこれひとつで完結する
              </span>
              から、コミュニティでの利用にも最適です！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "yuki_english2022",
            url: "https://www.instagram.com/yuki_english2022/",
          },
          image: "/images/creators/yuki-san.png",
        },
        {
          occupation: "フィギュアクリエイター",
          name: "渡部学",
          comment: (
            <Fragment>
              Pulpなら、サクサク講座が作れる。
              <span className="underline">
                文字が多めのコンテンツでも、ポップで見やすく仕上がります！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "YT",
            handle: "watabemanabu",
            url: "https://www.youtube.com/@watabemanabu",
          },
          image: "/images/creators/watabe-san.webp",
        },
        {
          occupation: "幸せ脳メソッド創始者・開発者",
          name: "青髪先生(牧野かよ)",
          comment: (
            <Fragment>
              新感覚ツールPulp。使うとわかる…かわいい！
              <span className="underline">
                これひとつで、自己紹介 • SNS • 講座のハブになる
              </span>
              ので、すごいです。
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "happybrain_makino",
            url: "https://www.instagram.com/happybrain_makino/",
          },
          image: "/images/creators/aogami-san.jpeg",
        },
        {
          occupation: "FP講師",
          name: "おーちゃん",
          comment: (
            <Fragment>
              専門知識なしでも、「指一本」で感覚的にコンテンツが作成できます。
              <span className="underline">
                やりたいことを自由自在に表現できる、まさにこんなツールが欲しかった！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "ochan1classfp",
            url: "https://twitter.com/ochan1classfp",
          },
          image: "/images/creators/ochan-san.png",
        },
        {
          // occupation: "",
          name: "Putti Monkey Wrench",
          comment: (
            <Fragment>
              <span className="underline">
                SNSには載せきれないノウハウを共有したり、作品の解説や、ポートフォリオとして使ったり。
              </span>
              様々な用途でコンテンツを作れそうなPulpのこれからが楽しみです。
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "PuttiMW",
            url: "https://twitter.com/PuttiMW",
          },
          image: "/images/creators/putti-san.jpg",
        },
      ],
    },
    Contents: {
      hidden: true,
      sectionTitle: "もっと伝わる、もっとつながる",
      // sectionDescription:
      //   "今日も、Pulpでいろんなコンテンツが作られています。",
      // background: "#292e34",

      sectionText: (
        <Fragment>
          文章・画像・動画・リンクなどを自由に組み合わせるだけで、
          <br />
          <span className="underline">
            ”スマホで見やすい”ホームページを簡単に作成できます。
          </span>
        </Fragment>
      ),
      background: "#9ad0e6",
      fontColor: "white",
      contents: [
        {
          image: "/images/v5/contents/1.png",
        },
        {
          image: "/images/v5/contents/2.png",
        },
        {
          image: "/images/contents/3.png",
        },
        {
          image: "/images/v5/contents/3.png",
        },
        {
          image: "/images/v5/contents/4.png",
        },
        {
          image: "/images/contents/5.png",
        },
        {
          image: "/images/contents/2.png",
        },
      ],
    },
    Signup: {
      sectionTitle: "あなただけのホームページを、Pulpで作ろう",
      sectionDescription: "Pulpは、事前登録を受け付けています。",
      background: "linear-gradient(45deg, #4ABDE1 0%, #A4E9FF 100%)",
      fontColor: "white",
      buttonText: "使ってみる！",
      imgUrl: "/images/join_pulp.png",
      formUrl: "https://forms.gle/81GKbDQ6LindWdP57",
    },
    Footer: {
      corporateSite: "https://lekcha.com/company",
      termsOfService: "https://lekcha.com/terms.html",
      privacyPolicy: "https://lekcha.com/privacy.html",
      instagram: "https://www.instagram.com/pulp_lekcha/",
    },
  },
  // 2023 Dec, 女性起業家向け、スマホ x ホームページ x ステップ配信 x マネタイズ
  v6: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    apiEndpoint: "https://lekcha-blocks-api.herokuapp.com",
    // apiEndpoint: "http://localhost:4000",
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/v6/hero.png",
      mainMessage: (
        <Fragment>
          <h1>
            あなたの「教える」を、
            <br />
            一つにまとめよう。
          </h1>
          {/* <p className="small">
            Pulp(パルプ)は、スマホで見やすいホームページを、
            <span className="underline">スマホで簡単に作成できる</span>
            サービスです。
          </p> */}
          {/* <p
            className="small"
          >
            <span className="opacity-5">見やすいコンテンツを、</span>
            <span className="underline big">スマホで簡単に作成。</span>
            <br />
            <span className="underline big">ステップ配信 & コンテンツ販売も、</span>
            <span className="opacity-5">スマホで完結。</span>
          </p> */}
          <p className="small">
            <span className="opacity-5">
              見やすいコンテンツ作成、ステップ配信、コンテンツ販売が、
            </span>
            <span className="underline big">スマホ一箇所で完結。</span>
          </p>
          {/* <p
            className="small"
            style={{
              backgroundColor: "white",
              padding: "20px 30px",
              borderRadius: "20px",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.14)",
            }}
          >
            <span
              style={{
                display: "block",
                fontSize: "2.2rem",
                fontWeight: "bold",
                opacity: 0.5,
                marginBottom: "10px",
              }}
            >
              Pulp(パルプ)なら...
            </span>
            <ul
              style={{
                margin: 0,
              }}
            >
              <li>
                スマホで見やすいホームページを、
                <br />
                <span className="underline big">スマホで簡単に作成。</span>
              </li>
              <li>
                <span className="underline big">
                  ステップ配信 & コンテンツ販売 
                </span>
                も
                <br />
                一箇所で完結
              </li>
            </ul>
          </p> */}
        </Fragment>
      ),
    },
    Introduction: {
      hidden: true,
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }} className="underline">
            クリエイターのためのホームツール
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "コンテンツも、プロフィールも、ファンも、一つに。",
        "Pulpリンク一つで、あなたのだけのスペースにすぐにアクセス。",
        "あなたのタイミングで、あなたらしいマネタイズを実現。",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "Pulpの特徴",
      // sectionTitle: "何が作れるの？",
      // sectionDescription: "Pulpを使うと、こんなことが実現します。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "スマホで操作　|　わかりやすい画面",
          title: "スキマ時間でも編集できる手軽さ。",
          body: (
            <Fragment>
              スマホに特化された編集画面で、直感的にコンテンツを作成・更新できます。
              <span className="underline">
                もう、更新の度にパソコンを立ち上げる必要はありません。
              </span>
            </Fragment>
          ),
          image: "/images/v5/values/1a.gif",
        },
        {
          preTitle: "文章　|　動画　|　ファイルダウンロード",
          title: "ハードルは低く、アレンジは無限大。",
          body: (
            <Fragment>
              1つの文章, 画像,
              動画から、自由に組み合わせた体系的なコンテンツまで。
              <span className="underline">
                {/* 小さく作ることも、リッチな内容にすることも、あなたの自由です。 */}
                教材コンテンツを始め、ウェブサイトやリンク集としても使えます。
              </span>
            </Fragment>
          ),
          image: "/images/v6/values/2.png",
        },
        {
          preTitle: "LINE連携　|　ステップ配信　|　決済",
          title: "わずらわしい作業は、全て一元化・自動化。",
          body: (
            <Fragment>
              複数のツールを横断利用して混乱する必要はありません。Pulpの機能群なら、
              <span className="underline">
                信頼獲得から販売まで、スマホ一つで簡単自動化。
              </span>
            </Fragment>
          ),
          image: "/images/v6/values/all-in-one.png",
        },
        {
          preTitle: "ホームスペース機能",
          // title: "あなたと生徒の、寄りどころ。",
          // title: "作ったコンテンツは、「ホームスペース」に集約",
          title: "作ったコンテンツは、「あなた専用の空間」に集約",
          body: (
            <Fragment>
              「ホームスペース」に、コンテンツをまとめましょう。
              特典コンテンツから有料コンテンツまで、訪問者に合わせて公開設定が可能。
              <span className="underline">
                はじめましての方にも、継続してくれている方にも、最適なコンテンツを届けられます。
              </span>
            </Fragment>
          ),
          image: "/images/screenshots/new/home.png",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
    Creators: {
      sectionTitle: "Pulpのクリエイターたち",
      sectionDescription: "私たちと一緒に、あなたの知識を解放しませんか？",
      background: "white",
      fontColor: "#292e34",
      creators: [
        {
          occupation: "編み物作家・講師",
          name: "AKKO",
          comment: (
            <Fragment>
              自分のオンライン教室が簡単に開けるツールPulp。
              <span className="underline">
                SNSのショート動画のような感覚で始められる
              </span>
              から、気軽に動画レッスンを作っていけます！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "tsukurite_akko",
            url: "https://www.instagram.com/tsukurite_akko/",
          },
          image: "/images/creators/akko-san.jpeg",
        },
        {
          occupation: "インスタグラマー",
          name: "ゆき英語",
          comment: (
            <Fragment>
              Pulpを使えば、見やすく・学びやすいコンテンツが簡単に作れる。
              <span className="underline">
                作成から共有までこれひとつで完結する
              </span>
              から、コミュニティでの利用にも最適です！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "yuki_english2022",
            url: "https://www.instagram.com/yuki_english2022/",
          },
          image: "/images/creators/yuki-san.png",
        },
        {
          occupation: "フィギュアクリエイター",
          name: "渡部学",
          comment: (
            <Fragment>
              Pulpなら、サクサク講座が作れる。
              <span className="underline">
                文字が多めのコンテンツでも、ポップで見やすく仕上がります！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "YT",
            handle: "watabemanabu",
            url: "https://www.youtube.com/@watabemanabu",
          },
          image: "/images/creators/watabe-san.webp",
        },
        {
          occupation: "幸せ脳メソッド創始者・開発者",
          name: "青髪先生(牧野かよ)",
          comment: (
            <Fragment>
              新感覚ツールPulp。使うとわかる…かわいい！
              <span className="underline">
                これひとつで、自己紹介 • SNS • 講座のハブになる
              </span>
              ので、すごいです。
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "happybrain_makino",
            url: "https://www.instagram.com/happybrain_makino/",
          },
          image: "/images/creators/aogami-san.jpeg",
        },
        {
          occupation: "FP講師",
          name: "おーちゃん",
          comment: (
            <Fragment>
              専門知識なしでも、「指一本」で感覚的にコンテンツが作成できます。
              <span className="underline">
                やりたいことを自由自在に表現できる、まさにこんなツールが欲しかった！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "ochan1classfp",
            url: "https://twitter.com/ochan1classfp",
          },
          image: "/images/creators/ochan-san.png",
        },
        // {
        //   // occupation: "",
        //   name: "Putti Monkey Wrench",
        //   comment: (
        //     <Fragment>
        //       <span className="underline">
        //         SNSには載せきれないノウハウを共有したり、作品の解説や、ポートフォリオとして使ったり。
        //       </span>
        //       様々な用途でコンテンツを作れそうなPulpのこれからが楽しみです。
        //     </Fragment>
        //   ),
        //   social: {
        //     platoform: "TW",
        //     handle: "PuttiMW",
        //     url: "https://twitter.com/PuttiMW",
        //   },
        //   image: "/images/creators/putti-san.jpg",
        // },
        {
          occupation: "ベーグル講師",
          name: "小南みお",
          comment: (
            <Fragment>
              とても使いやすくて、生徒さんにも
              <span className="underline">
                「教科書みたいで可愛いです」と言って頂けました！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "kominami.mio",
            url: "https://instagram.com/kominami.mio",
          },
          image: "/images/creators/kominami-san.jpg",
        },
      ],
    },
    Contents: {
      hidden: true,
      sectionTitle: "もっと伝わる、もっとつながる",
      // sectionDescription:
      //   "今日も、Pulpでいろんなコンテンツが作られています。",
      // background: "#292e34",

      sectionText: (
        <Fragment>
          文章・画像・動画・リンクなどを自由に組み合わせるだけで、
          <br />
          <span className="underline">
            ”スマホで見やすい”コンテンツを簡単に作成できます。
          </span>
        </Fragment>
      ),
      background: "#9ad0e6",
      fontColor: "white",
      contents: [
        {
          image: "/images/v5/contents/1.png",
        },
        {
          image: "/images/v6/contents/2.png",
        },
        {
          image: "/images/contents/3.png",
        },
        {
          image: "/images/v5/contents/3.png",
        },
        {
          image: "/images/v5/contents/4.png",
        },
        {
          image: "/images/contents/5.png",
        },
        {
          image: "/images/contents/2.png",
        },
      ],
    },
    Signup: {
      // sectionTitle: "あなたも、Pulpでコンテンツを作ってみませんか？",
      sectionTitle: "あなたの「教える」、1つにまとめませんか？",
      sectionDescription: "Pulpは、事前登録を受け付けています。",
      background: "linear-gradient(45deg, #4ABDE1 0%, #A4E9FF 100%)",
      fontColor: "white",
      buttonText: "使ってみる！",
      imgUrl: "/images/join_pulp.png",
      formUrl: "https://forms.gle/81GKbDQ6LindWdP57",
    },
    Footer: {
      corporateSite: "https://lekcha.com/company",
      termsOfService: "https://lekcha.com/terms.html",
      privacyPolicy: "https://lekcha.com/privacy.html",
      instagram: "https://www.instagram.com/pulp_lekcha/",
    },
  },
  // 2024 Dec, 利用規約・プライバシーポリシーのリンク変更および特商法の追加
  v7: {
    cta: {
      descriptionVisible: "事前登録はこちら！👇",
      descriptionHidden: "事前登録はこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSengD5BM8J2wgPGDiNM7ACmiEFC9IYgNoyGRM54RCzVqlG2rA/viewform?usp=pp_url&entry.1168232319=",
    },
    apiEndpoint: "https://lekcha-blocks-api.herokuapp.com",
    // apiEndpoint: "http://localhost:4000",
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/v6/hero.png",
      mainMessage: (
        <Fragment>
          <h1>
            あなたの「教える」を、
            <br />
            一つにまとめよう。
          </h1>
          {/* <p className="small">
            Pulp(パルプ)は、スマホで見やすいホームページを、
            <span className="underline">スマホで簡単に作成できる</span>
            サービスです。
          </p> */}
          {/* <p
            className="small"
          >
            <span className="opacity-5">見やすいコンテンツを、</span>
            <span className="underline big">スマホで簡単に作成。</span>
            <br />
            <span className="underline big">ステップ配信 & コンテンツ販売も、</span>
            <span className="opacity-5">スマホで完結。</span>
          </p> */}
          <p className="small">
            <span className="opacity-5">
              見やすいコンテンツ作成、ステップ配信、コンテンツ販売が、
            </span>
            <span className="underline big">スマホ一箇所で完結。</span>
          </p>
          {/* <p
            className="small"
            style={{
              backgroundColor: "white",
              padding: "20px 30px",
              borderRadius: "20px",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.14)",
            }}
          >
            <span
              style={{
                display: "block",
                fontSize: "2.2rem",
                fontWeight: "bold",
                opacity: 0.5,
                marginBottom: "10px",
              }}
            >
              Pulp(パルプ)なら...
            </span>
            <ul
              style={{
                margin: 0,
              }}
            >
              <li>
                スマホで見やすいホームページを、
                <br />
                <span className="underline big">スマホで簡単に作成。</span>
              </li>
              <li>
                <span className="underline big">
                  ステップ配信 & コンテンツ販売 
                </span>
                も
                <br />
                一箇所で完結
              </li>
            </ul>
          </p> */}
        </Fragment>
      ),
    },
    Introduction: {
      hidden: true,
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }} className="underline">
            クリエイターのためのホームツール
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "コンテンツも、プロフィールも、ファンも、一つに。",
        "Pulpリンク一つで、あなたのだけのスペースにすぐにアクセス。",
        "あなたのタイミングで、あなたらしいマネタイズを実現。",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "Pulpの特徴",
      // sectionTitle: "何が作れるの？",
      // sectionDescription: "Pulpを使うと、こんなことが実現します。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "スマホで操作　|　わかりやすい画面",
          title: "スキマ時間でも編集できる手軽さ。",
          body: (
            <Fragment>
              スマホに特化された編集画面で、直感的にコンテンツを作成・更新できます。
              <span className="underline">
                もう、更新の度にパソコンを立ち上げる必要はありません。
              </span>
            </Fragment>
          ),
          image: "/images/v5/values/1a.gif",
        },
        {
          preTitle: "文章　|　動画　|　ファイルダウンロード",
          title: "ハードルは低く、アレンジは無限大。",
          body: (
            <Fragment>
              1つの文章, 画像,
              動画から、自由に組み合わせた体系的なコンテンツまで。
              <span className="underline">
                {/* 小さく作ることも、リッチな内容にすることも、あなたの自由です。 */}
                教材コンテンツを始め、ウェブサイトやリンク集としても使えます。
              </span>
            </Fragment>
          ),
          image: "/images/v6/values/2.png",
        },
        {
          preTitle: "LINE連携　|　ステップ配信　|　決済",
          title: "わずらわしい作業は、全て一元化・自動化。",
          body: (
            <Fragment>
              複数のツールを横断利用して混乱する必要はありません。Pulpの機能群なら、
              <span className="underline">
                信頼獲得から販売まで、スマホ一つで簡単自動化。
              </span>
            </Fragment>
          ),
          image: "/images/v6/values/all-in-one.png",
        },
        {
          preTitle: "ホームスペース機能",
          // title: "あなたと生徒の、寄りどころ。",
          // title: "作ったコンテンツは、「ホームスペース」に集約",
          title: "作ったコンテンツは、「あなた専用の空間」に集約",
          body: (
            <Fragment>
              「ホームスペース」に、コンテンツをまとめましょう。
              特典コンテンツから有料コンテンツまで、訪問者に合わせて公開設定が可能。
              <span className="underline">
                はじめましての方にも、継続してくれている方にも、最適なコンテンツを届けられます。
              </span>
            </Fragment>
          ),
          image: "/images/screenshots/new/home.png",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
    Creators: {
      sectionTitle: "Pulpのクリエイターたち",
      sectionDescription: "私たちと一緒に、あなたの知識を解放しませんか？",
      background: "white",
      fontColor: "#292e34",
      creators: [
        {
          occupation: "編み物作家・講師",
          name: "AKKO",
          comment: (
            <Fragment>
              自分のオンライン教室が簡単に開けるツールPulp。
              <span className="underline">
                SNSのショート動画のような感覚で始められる
              </span>
              から、気軽に動画レッスンを作っていけます！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "tsukurite_akko",
            url: "https://www.instagram.com/tsukurite_akko/",
          },
          image: "/images/creators/akko-san.jpeg",
        },
        {
          occupation: "インスタグラマー",
          name: "ゆき英語",
          comment: (
            <Fragment>
              Pulpを使えば、見やすく・学びやすいコンテンツが簡単に作れる。
              <span className="underline">
                作成から共有までこれひとつで完結する
              </span>
              から、コミュニティでの利用にも最適です！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "yuki_english2022",
            url: "https://www.instagram.com/yuki_english2022/",
          },
          image: "/images/creators/yuki-san.png",
        },
        {
          occupation: "フィギュアクリエイター",
          name: "渡部学",
          comment: (
            <Fragment>
              Pulpなら、サクサク講座が作れる。
              <span className="underline">
                文字が多めのコンテンツでも、ポップで見やすく仕上がります！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "YT",
            handle: "watabemanabu",
            url: "https://www.youtube.com/@watabemanabu",
          },
          image: "/images/creators/watabe-san.webp",
        },
        {
          occupation: "幸せ脳メソッド創始者・開発者",
          name: "青髪先生(牧野かよ)",
          comment: (
            <Fragment>
              新感覚ツールPulp。使うとわかる…かわいい！
              <span className="underline">
                これひとつで、自己紹介 • SNS • 講座のハブになる
              </span>
              ので、すごいです。
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "happybrain_makino",
            url: "https://www.instagram.com/happybrain_makino/",
          },
          image: "/images/creators/aogami-san.jpeg",
        },
        {
          occupation: "FP講師",
          name: "おーちゃん",
          comment: (
            <Fragment>
              専門知識なしでも、「指一本」で感覚的にコンテンツが作成できます。
              <span className="underline">
                やりたいことを自由自在に表現できる、まさにこんなツールが欲しかった！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "ochan1classfp",
            url: "https://twitter.com/ochan1classfp",
          },
          image: "/images/creators/ochan-san.png",
        },
        // {
        //   // occupation: "",
        //   name: "Putti Monkey Wrench",
        //   comment: (
        //     <Fragment>
        //       <span className="underline">
        //         SNSには載せきれないノウハウを共有したり、作品の解説や、ポートフォリオとして使ったり。
        //       </span>
        //       様々な用途でコンテンツを作れそうなPulpのこれからが楽しみです。
        //     </Fragment>
        //   ),
        //   social: {
        //     platoform: "TW",
        //     handle: "PuttiMW",
        //     url: "https://twitter.com/PuttiMW",
        //   },
        //   image: "/images/creators/putti-san.jpg",
        // },
        {
          occupation: "ベーグル講師",
          name: "小南みお",
          comment: (
            <Fragment>
              とても使いやすくて、生徒さんにも
              <span className="underline">
                「教科書みたいで可愛いです」と言って頂けました！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "kominami.mio",
            url: "https://instagram.com/kominami.mio",
          },
          image: "/images/creators/kominami-san.jpg",
        },
      ],
    },
    Contents: {
      hidden: true,
      sectionTitle: "もっと伝わる、もっとつながる",
      // sectionDescription:
      //   "今日も、Pulpでいろんなコンテンツが作られています。",
      // background: "#292e34",

      sectionText: (
        <Fragment>
          文章・画像・動画・リンクなどを自由に組み合わせるだけで、
          <br />
          <span className="underline">
            ”スマホで見やすい”コンテンツを簡単に作成できます。
          </span>
        </Fragment>
      ),
      background: "#9ad0e6",
      fontColor: "white",
      contents: [
        {
          image: "/images/v5/contents/1.png",
        },
        {
          image: "/images/v6/contents/2.png",
        },
        {
          image: "/images/contents/3.png",
        },
        {
          image: "/images/v5/contents/3.png",
        },
        {
          image: "/images/v5/contents/4.png",
        },
        {
          image: "/images/contents/5.png",
        },
        {
          image: "/images/contents/2.png",
        },
      ],
    },
    Signup: {
      // sectionTitle: "あなたも、Pulpでコンテンツを作ってみませんか？",
      sectionTitle: "あなたの「教える」、1つにまとめませんか？",
      sectionDescription: "Pulpは、事前登録を受け付けています。",
      background: "linear-gradient(45deg, #4ABDE1 0%, #A4E9FF 100%)",
      fontColor: "white",
      buttonText: "使ってみる！",
      imgUrl: "/images/join_pulp.png",
      formUrl: "https://forms.gle/81GKbDQ6LindWdP57",
    },
    Footer: {
      corporateSite: "https://lekcha.com/company",
      termsOfService:
        "https://lekcha.notion.site/14bd5b5cfa6f8089a4a4f00310f7091b",
      privacyPolicy:
        "https://lekcha.notion.site/14bd5b5cfa6f80cf8abeeba94c08d3b6",
      transactionLaw:
        "https://lekcha.notion.site/14bd5b5cfa6f8039bf58d7b86dfb7732",
      instagram: "https://www.instagram.com/pulp_lekcha/",
    },
  },

  // 2025, Mar.　NextJs移行後の変更点
  v8: {
    cta: {
      descriptionVisible: "お申し込みはこちら！👇",
      descriptionHidden: "お申し込みはこちら",
      urlBase:
        "https://docs.google.com/forms/d/e/1FAIpQLSeYxKOZd56PMo-ijhAXin1wJAi3K0sPkXgfSCPUInKGN-Jjfg/viewform?usp=pp_url&entry.20762516=",
    },
    apiEndpoint: "https://lekcha-blocks-api.herokuapp.com",
    // apiEndpoint: "http://localhost:4000",
    appIcon: "/icons/pulp_logo_paper.svg",
    appIconWithBg: "/icons/pulp_icon.svg",
    appIconText: "/icons/pulp.svg",
    appIconTextGradient: "/icons/pulp_gradient.svg",
    Hero: {
      background: "transparent",
      mainImage: "/images/v6/hero.png",
      mainMessage: (
        <Fragment>
          <h1>
            あなたの「教える」を、
            <br />
            一つにまとめよう。
          </h1>
          {/* <p className="small">
            Pulp(パルプ)は、スマホで見やすいホームページを、
            <span className="underline">スマホで簡単に作成できる</span>
            サービスです。
          </p> */}
          {/* <p
            className="small"
          >
            <span className="opacity-5">見やすいコンテンツを、</span>
            <span className="underline big">スマホで簡単に作成。</span>
            <br />
            <span className="underline big">ステップ配信 & コンテンツ販売も、</span>
            <span className="opacity-5">スマホで完結。</span>
          </p> */}
          <p className="small">
            <span className="opacity-5">
              見やすいコンテンツ作成、ステップ配信、コンテンツ販売が、
            </span>
            <span className="underline big">スマホ一箇所で完結。</span>
          </p>
          {/* <p
            className="small"
            style={{
              backgroundColor: "white",
              padding: "20px 30px",
              borderRadius: "20px",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.14)",
            }}
          >
            <span
              style={{
                display: "block",
                fontSize: "2.2rem",
                fontWeight: "bold",
                opacity: 0.5,
                marginBottom: "10px",
              }}
            >
              Pulp(パルプ)なら...
            </span>
            <ul
              style={{
                margin: 0,
              }}
            >
              <li>
                スマホで見やすいホームページを、
                <br />
                <span className="underline big">スマホで簡単に作成。</span>
              </li>
              <li>
                <span className="underline big">
                  ステップ配信 & コンテンツ販売 
                </span>
                も
                <br />
                一箇所で完結
              </li>
            </ul>
          </p> */}
        </Fragment>
      ),
    },
    Introduction: {
      hidden: true,
      background: "white",
      quoteEmoji: "👋",
      quote: (
        <Fragment>
          Hi!
          <br />
          We are Pulp.
        </Fragment>
      ),
      description: (
        <Fragment>
          Pulpは、「コンテンツ作成」と「オーディエンス管理」が一つになった、
          <br className="mobile-hide" />
          <span style={{ color: "#ff7f47" }} className="underline">
            クリエイターのためのホームツール
          </span>{" "}
          です。
        </Fragment>
      ),
      pointsIconColor: "linear-gradient(126deg, #FF7F46 18.23%, #96FABD 130%)",
      // pointsIconColor: "linear-gradient(140deg, #4ABDE1 0%, #A5E9FF 100%)",
      points: [
        "コンテンツも、プロフィールも、ファンも、一つに。",
        "Pulpリンク一つで、あなたのだけのスペースにすぐにアクセス。",
        "あなたのタイミングで、あなたらしいマネタイズを実現。",
      ],
    },
    WhatYouCanCreate: {
      hidden: true,
      // sectionTitle: "何が作れるの？",
      sectionTitle: "スマホ一つで始める、あなたのPulp。",
      // sectionDescription:
      //   "Pulpでコンテンツを作って、Pulpでファンに届けることができます。",
      background: "#292e34",
      fontColor: "white",
      items: [
        {
          title: "コンテンツを作成",
          description: "没入感のあるコンテンツを、スマホで簡単に作成できます。",
          body: "テキストも、画像も、動画も。ブロックを組み合わせて、どんな媒体でもコンテンツに。",
          image: "/images/placeholders/mockup.png",
        },
        {
          title: "プロフィールにまとめる",
          description:
            "あなたの活動紹介から、フリーコンテンツ、有料コンテンツまで、一つのプロフィールにまとめて公開。",
          image: "/images/placeholders/mockup.png",
        },
      ],
    },
    Values: {
      sectionTitle: "Pulpの特徴",
      // sectionTitle: "何が作れるの？",
      // sectionDescription: "Pulpを使うと、こんなことが実現します。",
      background: "linear-gradient(126deg, #FF7F46 -30%, #96FABD 130%)",
      fontColor: "white",
      items: [
        {
          preTitle: "スマホで操作　|　わかりやすい画面",
          title: "スキマ時間でも編集できる手軽さ。",
          body: (
            <Fragment>
              スマホに特化された編集画面で、直感的にコンテンツを作成・更新できます。
              <span className="underline">
                もう、更新の度にパソコンを立ち上げる必要はありません。
              </span>
            </Fragment>
          ),
          image: "/images/v5/values/1a.gif",
        },
        {
          preTitle: "文章　|　動画　|　ファイルアップロード",
          title: "ハードルは低く、アレンジは無限大。",
          body: (
            <Fragment>
              1つの文章, 画像,
              動画から、自由に組み合わせた体系的なコンテンツまで。
              <span className="underline">
                {/* 小さく作ることも、リッチな内容にすることも、あなたの自由です。 */}
                教材コンテンツを始め、ウェブサイトやリンク集としても使えます。
              </span>
            </Fragment>
          ),
          image: "/images/v6/values/2.png",
        },
        {
          preTitle: "LINE連携　|　ステップ配信　|　決済　　※",
          title: "わずらわしい作業は、全て一元化・自動化。",
          body: (
            <Fragment>
              複数のツールを横断利用して混乱する必要はありません。Pulpの機能群なら、
              <span className="underline">
                信頼獲得から販売まで、スマホ一つで簡単自動化。
              </span>
              <br />
              <br />
              <span style={{ fontSize: "14px", opacity: 0.7 }}>
                ※これらの機能は今後リリース予定。
              </span>
            </Fragment>
          ),
          image: "/images/v6/values/all-in-one.png",
        },
        {
          preTitle: "スペース機能",
          // title: "あなたと生徒の、寄りどころ。",
          // title: "作ったコンテンツは、「ホームスペース」に集約",
          title: "作ったコンテンツは、「あなた専用の空間」に集約",
          body: (
            <Fragment>
              「スペース」に、コンテンツをまとめましょう。
              レッスン動画や受講生向けの資料など、豊富なコンテンツも見出しごとに見やすく配置。アクセス権の制限も柔軟に調整できるため、
              <span className="underline">
                講座や受講生ごとに適したコンテンツを届けられます。
              </span>
            </Fragment>
          ),
          image: "/images/screenshots/v8/home.png",
        },
      ],
    },
    Features: {
      hidden: true,
      sectionTitle: "Pulpの機能",
      sectionDescription:
        "すぐに始められて、自分のペースでどこまでもスケール。",
      background: "white",
      cardBackground: "white",
      cardFontColor: "#292e34",
      features: [
        {
          emoji: "📱",
          title: "コンテンツ作成機能",
          description: "没入感のあるコンテンツを、スマホから簡単に作成。",
        },
        {
          emoji: "🙋🏻‍♂️",
          title: "プロフィール機能",
          description: "作ったコンテンツは、あなた専用のスペースに配置。",
        },
        {
          emoji: "🧺",
          title: "バスケット機能",
          description:
            "コンテンツが増えてきたら、テーマやオーディエンスごとにまとめましょう。バスケット単位での公開設定や販売も可能です。",
        },
        {
          emoji: "🤝",
          title: "ピープル機能",
          description: "Pulpでできた接点は、ピープル画面に記録されていきます。",
        },
        {
          emoji: "🛍️",
          title: "販売機能",
          description:
            "買い切り・サブスク・投げ銭など、多様な販売設定で、あなたらしいマネタイズを。",
        },
      ],
    },
    Creators: {
      sectionTitle: "Pulpのクリエイターたち",
      sectionDescription: "私たちと一緒に、あなたの知識を解放しませんか？",
      background: "white",
      fontColor: "#292e34",
      creators: [
        {
          occupation: "編み物作家・講師",
          name: "AKKO",
          comment: (
            <Fragment>
              自分のオンライン教室が簡単に開けるツールPulp。
              <span className="underline">
                SNSのショート動画のような感覚で始められる
              </span>
              から、気軽に動画レッスンを作っていけます！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "tsukurite_akko",
            url: "https://www.instagram.com/tsukurite_akko/",
          },
          image: "/images/creators/akko-san.jpeg",
        },
        {
          occupation: "インスタグラマー",
          name: "ゆき英語",
          comment: (
            <Fragment>
              Pulpを使えば、見やすく・学びやすいコンテンツが簡単に作れる。
              <span className="underline">
                作成から共有までこれひとつで完結する
              </span>
              から、コミュニティでの利用にも最適です！
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "yuki_english2022",
            url: "https://www.instagram.com/yuki_english2022/",
          },
          image: "/images/creators/yuki-san.png",
        },
        {
          occupation: "フィギュアクリエイター",
          name: "渡部学",
          comment: (
            <Fragment>
              Pulpなら、サクサク講座が作れる。
              <span className="underline">
                文字が多めのコンテンツでも、ポップで見やすく仕上がります！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "YT",
            handle: "watabemanabu",
            url: "https://www.youtube.com/@watabemanabu",
          },
          image: "/images/creators/watabe-san.webp",
        },
        {
          occupation: "幸せ脳メソッド創始者・開発者",
          name: "青髪先生(牧野かよ)",
          comment: (
            <Fragment>
              新感覚ツールPulp。使うとわかる…かわいい！
              <span className="underline">
                これひとつで、自己紹介 • SNS • 講座のハブになる
              </span>
              ので、すごいです。
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "happybrain_makino",
            url: "https://www.instagram.com/happybrain_makino/",
          },
          image: "/images/creators/aogami-san.jpeg",
        },
        {
          occupation: "FP講師",
          name: "おーちゃん",
          comment: (
            <Fragment>
              専門知識なしでも、「指一本」で感覚的にコンテンツが作成できます。
              <span className="underline">
                やりたいことを自由自在に表現できる、まさにこんなツールが欲しかった！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "TW",
            handle: "ochan1classfp",
            url: "https://twitter.com/ochan1classfp",
          },
          image: "/images/creators/ochan-san.png",
        },
        // {
        //   // occupation: "",
        //   name: "Putti Monkey Wrench",
        //   comment: (
        //     <Fragment>
        //       <span className="underline">
        //         SNSには載せきれないノウハウを共有したり、作品の解説や、ポートフォリオとして使ったり。
        //       </span>
        //       様々な用途でコンテンツを作れそうなPulpのこれからが楽しみです。
        //     </Fragment>
        //   ),
        //   social: {
        //     platoform: "TW",
        //     handle: "PuttiMW",
        //     url: "https://twitter.com/PuttiMW",
        //   },
        //   image: "/images/creators/putti-san.jpg",
        // },
        {
          occupation: "ベーグル講師",
          name: "小南みお",
          comment: (
            <Fragment>
              とても使いやすくて、生徒さんにも
              <span className="underline">
                「教科書みたいで可愛いです」と言って頂けました！
              </span>
            </Fragment>
          ),
          social: {
            platoform: "IG",
            handle: "kominami.mio",
            url: "https://instagram.com/kominami.mio",
          },
          image: "/images/creators/kominami-san.jpg",
        },
      ],
    },
    Contents: {
      hidden: true,
      sectionTitle: "もっと伝わる、もっとつながる",
      // sectionDescription:
      //   "今日も、Pulpでいろんなコンテンツが作られています。",
      // background: "#292e34",

      sectionText: (
        <Fragment>
          文章・画像・動画・リンク・PDFなどを自由に組み合わせるだけで、
          <br />
          <span className="underline">
            ”スマホで見やすい”コンテンツを簡単に作成できます。
          </span>
        </Fragment>
      ),
      background: "#9ad0e6",
      fontColor: "white",
      contents: [
        {
          image: "/images/v5/contents/1.png",
        },
        {
          image: "/images/v6/contents/2.png",
        },
        {
          image: "/images/contents/3.png",
        },
        {
          image: "/images/v5/contents/3.png",
        },
        {
          image: "/images/v5/contents/4.png",
        },
        {
          image: "/images/contents/5.png",
        },
        {
          image: "/images/contents/2.png",
        },
      ],
    },
    Signup: {
      sectionTitle: "あなたの「教える」、1つにまとめませんか？",
      background: "linear-gradient(45deg, #4ABDE1 0%, #A4E9FF 100%)",
      fontColor: "white",
      buttonText: "使ってみる！",
      imgUrl: "/images/join_pulp.png",
      formUrl: "https://forms.gle/Pmdq52jkXic69tH26",
    },
    Footer: {
      corporateSite: "https://lekcha.com/company",
      termsOfService:
        "https://lekcha.notion.site/14bd5b5cfa6f8089a4a4f00310f7091b",
      privacyPolicy:
        "https://lekcha.notion.site/14bd5b5cfa6f80cf8abeeba94c08d3b6",
      transactionLaw:
        "https://lekcha.notion.site/14bd5b5cfa6f8039bf58d7b86dfb7732",
      instagram: "https://www.instagram.com/pulphq/",
    },
  },
};

export default lpData.v8;
